@import "../../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 56px;

  max-width: 484px;
  width: 100%;

  & .title {
    color: $orange-600;
  }

  & .btn {
    width: fit-content;
    padding-left: 44px;
    padding-right: 44px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.headerWrapper,
.emailWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
