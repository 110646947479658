@import "../../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;

  padding: 64px;

  border-radius: 16px;

  background: $white;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  max-width: 400px;

  text-align: center;
}

.wrapperBtn {
  display: flex;
  align-items: center;
  gap: 16px;

  max-width: 320px;
  width: 100%;
}

.btn {
  width: 152px;
  justify-content: center;
}
