@import "../../../../assets/styles/services.scss";

.root {
  position: absolute;
  top: calc(100% - 20px);
  right: 16px;

  display: flex;
  flex-direction: column;
  gap: 4px;

  max-width: 224px;
  width: 100%;

  padding: 4px;

  border-radius: 8px;

  background: $white;

  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);

  .labelTab {
    display: flex;

    width: 100%;

    padding: 10px 16px;

    cursor: pointer;

    text-decoration: none;
    color: unset;

    font-size: 14px;
    line-height: 19px;

    &:hover {
      border-radius: 8px;

      background: $bw-200;
    }

    &:active {
      color: $orange-600;

      & svg path {
        stroke: $orange-600;
      }
    }
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-weight: 500;

  list-style-type: none;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
