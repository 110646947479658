.root {
  display: flex;
  flex-direction: column;
  gap: 24px;

  max-width: 541px;
  width: 100%;
}

.order {
  order: 1;
}

.wrapperBlocks {
  margin-top: 39px;
}
