@font-face {
  font-family: "Inter";
  src:
    local("Inter"),
    url("../../fonts/Inter.woff2") format("woff2"),
    url("../../fonts/Inter.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
