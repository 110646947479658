@import "../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  & label {
    width: 100%;

    &:focus-within {
      outline: none;
    }
  }

  & .loader {
    background:
      radial-gradient(farthest-side, #4dd474 94%, #0000) top/9px 9px no-repeat,
      conic-gradient(#0000 30%, #4dd474);
  }
}

.uploadField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  padding: 24px 0;

  border-radius: 16px;
  border: 1px dashed $orange-600;

  cursor: pointer;

  &:hover {
    background: $bw-100;
  }
}

.dropWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
}

.loadIcon {
  width: 20px;
  height: 20px;

  & path {
    stroke: $orange-600;
  }
}

.lineWrapper {
  display: flex;
  align-items: center;

  width: 100%;

  padding: 0 23px;
}

.line {
  flex-grow: 1;
  height: 1px;

  background-color: $bw-300;
}

.textOr {
  margin: 0 12px;

  font-size: 14px;
  line-height: 18.9px;

  color: $bw-500;
}

.uploadFieldText {
  font-size: 14px;
  line-height: 19px;

  color: $bw-700;
}

.btn {
  font-weight: 600;
}

.text {
  text-align: center;

  font-size: 14px;
  line-height: 18.9px;

  color: $bw-500;
}

.fileViewWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 481px;
  width: 100%;

  padding: 18px;

  margin-top: 100px;

  border-radius: 12px;
  border: 2px solid $bw-200;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 12px;

  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.deleteIcon {
  width: 24px;
  height: 24px;

  & path {
    stroke: $bw-500;

    &:hover {
      stroke: $bw-700;
    }
  }
}
