@import "../../../../assets/styles/services";

.root {
  display: flex;
  flex-direction: column;
  gap: 18px;

  max-width: 541px;
  width: 100%;

  .template {
    padding: 14px 22px;
  }

  .lastTemplate {
    padding: 14px 22px;
    margin-top: 32px;
  }
}

.description {
  color: $bw-600;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
