.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    width: 188px;

    padding: 32px 25.5px;

    border: 1px solid #f1f1f1;
    border-radius: 16px;

    box-shadow: 0 8px 24px 0 #00000014;
  }
}

.wrapper {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.portalWrapper {
  justify-content: flex-end;

  padding: 16px;
}
