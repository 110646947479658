@import "../../../../../assets/styles/services.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 24px 18px 24px;

  border-bottom: 1px solid rgba(241, 241, 241, 1);

  box-shadow: 0 4px 10px 0 rgba(49, 49, 49, 0.04);

  background: rgba(255, 255, 255, 1);

  & .wrapper .shareBtn {
    display: flex;
    gap: 4px;

    color: $bw-700;

    &:hover {
      color: $orange-500;

      & svg {
        & path {
          stroke: $orange-500;
        }
      }
    }

    &:active {
      color: $orange-700;

      & svg {
        & path {
          stroke: $orange-700;
        }
      }
    }

    & svg {
      & path {
        stroke: $bw-700;
      }
    }
  }
}

.wrapperSelect {
  width: 330px;
}

.wrapper {
  position: relative;

  display: flex;
  gap: 44px;
}

.arrow {
  transform: rotate(-90deg);
  transition: transform 0.3s ease;
}

.openMenu {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.portalWrapper {
  align-items: center;
  justify-content: center;
}
