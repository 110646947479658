@import "../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.withoutBorder {
    gap: 4px;
  }

  &.labelInsideBorder {
    gap: 0;

    padding: 4px 16px;

    border-radius: 8px;
    border: 1px solid $bw-300;
  }
}
