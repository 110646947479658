@import "../../../assets/styles/services";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 650px;
}

.doughnutStyle {
  height: 750px;
}

.wrapperPie {
  height: 505px;
}

.doughnutIssuesStyle {
  height: 480px;
}
