@import "../../../../assets/styles/services";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;

  min-height: 760px;

  padding: 64px 72px;
}

.wrapperBtn {
  display: flex;
  align-items: center;
  gap: 16px;

  max-width: 320px;
  width: 100%;

  margin-top: 16px;
}

.btn {
  width: 152px;
  justify-content: center;

  &:hover .icon {
    path {
      stroke: $orange-500;
    }
  }

  &:active .icon {
    path {
      stroke: $orange-700;
    }
  }
}

.portalWrapper {
  align-items: center;
  justify-content: center;
}

.doughnutStyle {
  height: 750px;
}

.wrapperPie {
  height: 505px;
}

.doughnutIssuesStyle {
  height: 480px;
}
