@import "../../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.description {
  color: $bw-600;
}
