@import "../../../../../assets/styles/services.scss";

.root {
  width: 100%;
  min-width: calc(80% - 48px);
  overflow-x: auto;
}

.table {
  width: 100%;

  border-radius: 16px;

  border: 1px solid $bw-300;

  box-shadow: 0 8px 24px 0 #00000014;
  border-spacing: 0;
  border-collapse: separate;

  tr td {
    border-bottom: 1px solid rgba(226, 226, 226, 1);
  }

  tr:last-child td {
    border-bottom: unset;
  }
}

.tableRow {
  background: $bw-200;

  th {
    padding: 14px 24px;

    white-space: nowrap;

    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
  }

  th:last-child {
    border-radius: 0 16px 0 0;
  }

  th:first-child {
    border-radius: 16px 0 0 0;
  }
}

.row td {
  padding: 14px 24px;
}

.nameProd {
  padding: 14px 44px 14px 24px;

  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.hours,
.cost {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  text-align: right;
}

.start,
.finish {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}

.description {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
