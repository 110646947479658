.root {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px 24px;
}

.headerSideMenu {
  justify-content: space-between;
}

.logoWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  width: 120px;
  height: 37px;
}

.logoIcon {
  width: 37px;
  height: 37px;
}

.logoTextIcon {
  width: 72px;
  height: 31px;
}

.portalWrapper {
  justify-content: flex-end;

  padding: 16px;
}

.portalLogoutWrapper {
  justify-content: center;
  align-items: center;
}
