@import "../../assets/styles/services";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  margin-top: 50px;
}
.wrapper {
  display: flex;
  justify-content: center;
  gap: 35px;

  width: 100%;
}

.line {
  position: relative;

  &::before {
    content: "";
    position: absolute;

    background: $bw-400;
    width: 1px;
    height: 100%;
  }
}

.wrapperBtn {
  display: flex;
  align-items: center;
  gap: 16px;

  max-width: 320px;
  width: 100%;

  margin-top: 47px;
}

.btn {
  width: 152px;
  justify-content: center;

  &:hover .icon {
    path {
      stroke: $orange-500;
    }
  }

  &:active .icon {
    path {
      stroke: $orange-700;
    }
  }
}

.wrapperSupport {
  display: flex;
  gap: 8px;

  margin-top: 39px;
}

.text {
  color: $bw-700;
}

.supLink {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}
