@import "../../../../assets/styles/services";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  margin-top: 88px;
}

.description {
  max-width: 460px;

  text-align: center;

  color: $bw-600;
}
