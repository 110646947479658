.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  padding: 0 24px;
}
