.wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
}

.wrapperText {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  text-align: center;
}

.btn {
  justify-content: center;
}
