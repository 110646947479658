.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  padding: 56px 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 27px;

  max-width: 850px;

  & ul {
    padding-left: 35px;
  }
}

.textBolt {
  font-weight: bold;
}

.link {
  font-size: 16px;
}

.wrapperText {
  padding: 27px 0;
}
