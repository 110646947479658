@import "../../assets/styles/services.scss";

.root {
  min-height: 100%;
}

.rootSideMenu {
  display: flex;
}

.wrapperContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  &.wrapperContentCalcWidthMenuOpen {
    max-width: calc(100% - 240px);
  }

  &.wrapperContentCalcWidthMenuClose {
    max-width: calc(100% - 100px);
  }
}

.main {
  flex-grow: 1;
}
