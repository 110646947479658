@import "../../../../assets/styles/services.scss";

.root {
  position: relative;

  display: flex;
  align-items: center;
  gap: 16px;

  z-index: 1;
}

.avatarIcon {
  width: 40px;
  height: 40px;

  border-radius: 50%;

  &:hover {
    border: 1px solid $orange-600;
  }
}
