.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: stretch;
  gap: 20px;
}
