@import "../../../../assets/styles/services.scss";

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px 0;

  font-size: 14px;
  line-height: 19px;

  color: $bw-600;
}
