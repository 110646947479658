.root {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
