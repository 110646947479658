@import "../../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 48px;

  max-width: 360px;
  width: 100%;

  & .submitBtn {
    width: fit-content;

    padding-left: 57px;
    padding-right: 57px;
  }
}

.fieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.emailStar {
  color: $orange-600;
}
