@import "../../../../../assets/styles/services.scss";

.root {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  gap: 4px;

  & .select {
    width: 200px;
  }
}

.infoField {
  padding: 8px 16px;

  border-radius: 8px;
  border: 1px solid $bw-300;
}

.infoFieldTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  color: $orange-600;
}

.infoFieldSubtitle {
  color: $bw-700;
}
