.root {
  margin: 0;
}

.h1 {
  font-family: "Inter";
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
}

.h2 {
  font-family: "Inter";
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
}

.h3 {
  font-family: "Inter";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.h4 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}

.h5 {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
