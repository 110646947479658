@import "../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;

  min-height: 430px;
  width: 100%;

  padding: 32px 24px;

  border-radius: 16px;
  border: 1px solid $bw-300;

  box-shadow: 0px 8px 24px 0px #00000014;
}

.pieWrapper {
  height: 100%;
}
