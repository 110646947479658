@import "../../assets/styles/services";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 640px;
  width: 100%;

  padding: 22px;

  border-radius: 16px;
  border: 2px solid $bw-200;

  font-weight: 500;
  line-height: 22px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.wrapperDemo {
  justify-content: space-between;

  width: 100%;
}

.disabledTitle {
  color: $bw-500;
}

.wrapper {
  position: relative;
}

.helpIcon {
  width: 24px;
  height: 24px;
}

.downloadBtn {
  justify-content: center;
  width: 117px;
}

.hiddenBtn {
  display: none;
}

.portalWrapper {
  justify-content: flex-end;

  padding: 16px;
}
