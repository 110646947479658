@import "../services.scss";

* {
  padding: 0;

  margin: 0;

  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100%;
}

body {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: $black;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: $white;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;

  background: #c1c1c1;
}
