@import "../../../../assets/styles/services";

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 481px;
  width: 100%;

  padding: 18px;

  border-radius: 12px;
  border: 2px solid $bw-200;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 12px;

  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.loader {
  background:
    radial-gradient(farthest-side, #4dd474 94%, #0000) top/9px 9px no-repeat,
    conic-gradient(#0000 30%, #4dd474);
}

.deleteIcon {
  width: 24px;
  height: 24px;

  & path {
    stroke: $bw-500;

    &:hover {
      stroke: $bw-700;
    }
  }
}
