@import "../../../../../assets/styles/services";

.root {
  display: flex;
  justify-content: space-between;
}

.infoWrapper {
  display: flex;
  gap: 8px;
}

.loader {
  background:
    radial-gradient(farthest-side, $orange-600 94%, #0000) top/9px 9px no-repeat,
    conic-gradient(#0000 30%, $orange-600);
}
