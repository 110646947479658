.root {
  display: flex;
  justify-content: flex-end;
  gap: 4px;

  & .bigSelect {
    min-width: 200px;
    max-width: 200px;
  }

  & .dateInput {
    max-width: 170px;
  }
}

.wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}
