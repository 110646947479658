@import "../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 32px;

  min-height: 430px;
  width: 100%;

  padding: 32px 24px;

  border: 1px solid $bw-300;
  border-radius: 16px;

  box-shadow: 0 8px 24px 0 #00000014;
}

.dashBoardWrapper {
  height: 100%;
}
