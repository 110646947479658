.root {
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .submitBtn {
    justify-content: center;

    width: 100%;
  }

  .signUpBtn {
    align-self: flex-end;

    height: auto;

    padding: 0;

    font-size: 14px;
    line-height: 19px;
  }
}

.btnsAdditionalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
