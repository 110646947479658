.root {
  display: flex;
  flex-direction: column;
  gap: 48px;

  padding: 32px 10px 40px 24px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
