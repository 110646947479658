@import "../../../../assets/styles/services.scss";

.root {
  position: absolute;
  bottom: 38px;
  right: -18px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  max-width: 328px;
  width: 328px;

  padding: 12px;

  border-radius: 8px;

  background: $white;

  box-shadow: 0 12px 16px -4px #10182814;

  line-height: 18px;

  color: $bw-600;

  &::before {
    content: "";

    position: absolute;
    top: 100%;
    right: 18px;

    width: 12px;
    height: 12px;

    border-radius: 1px;

    background: $white;

    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.btn {
  padding: unset;
  height: unset;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
