// main
$black: #000000;
$white: #ffffff;

// b&w
$bw-900: #171717;
$bw-800: #404040;
$bw-700: #5e5e5e;
$bw-600: #7d7d7d;
$bw-500: #9e9e9e;
$bw-400: #cbcbcb;
$bw-300: #e2e2e2;
$bw-200: #f1f1f1;
$bw-100: #f9f9f9;

// orange
$orange-700: #e95b00;
$orange-600: #ff6300;
$orange-500: #ff8436;
$orange-400: #ffa267;
$orange-300: #ffbf97;
$orange-200: #ffe5d4;
$orange-100: #fff2ed;

$error: #e90e2a;
$warning: #ffcc00;
$success: #26cb55;
