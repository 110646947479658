@import "../../../../assets/styles/services";

.root {
  display: flex;
  flex-direction: column;
  gap: 56px;

  margin: 105px 0 0 70px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.friday {
  color: $orange-600;
}

.wrapperText {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text {
  max-width: 400px;

  color: $bw-800;
}

.button {
  justify-content: center;

  width: 152px;

  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
}
