@import "../../assets/styles/services.scss";

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 9px;

  height: 100%;
  width: 100%;

  padding: 36px 0 36px 24px;
}
