.root {
  & .forgotPassBtn {
    align-self: flex-end;

    height: auto;

    padding: 0;

    font-size: 14px;
    line-height: 19px;
  }
}

.passwordWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
