@import "../../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.text {
  font-size: 12px;
  line-height: 18px;

  color: $bw-800;
}
