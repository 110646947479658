@import "../../assets/styles/services";

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}

.title {
  margin-top: 56px;
}

.image {
  max-width: 1200px;
  width: 100%;

  padding: 24px;

  margin-top: 48px;

  border-radius: 16px;
  border: 1px solid rgba(226, 226, 226, 1);
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08);
}

.wrapperBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  max-width: 320px;
  width: 100%;

  margin-top: 16px;
  margin-bottom: 40px;
}

.btn {
  width: 152px;
  justify-content: center;

  &:hover .icon {
    path {
      stroke: $orange-500;
    }
  }

  &:active .icon {
    path {
      stroke: $orange-700;
    }
  }
}

.footerLogin {
  position: fixed;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 86px;

  background: #000000a6;
}

.loginBtn {
  justify-content: center;

  width: 173px;
}

.portalWrapper {
  position: fixed;

  justify-content: flex-end;

  padding: 16px;
}
