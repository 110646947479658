@import "../../../../assets/styles/services";

.root {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;

  border-radius: 16px;
  border: 1px solid rgba(241, 241, 241, 1);

  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08);
}

.hint {
  position: absolute;
  top: -26px;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 8px 12px;

  white-space: nowrap;

  border-radius: 4px;

  background: $white;

  box-shadow:
    0 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  z-index: 10;

  &::before {
    content: "";

    position: absolute;
    top: 30px;
    left: 50%;

    width: 12px;
    height: 12px;

    border-radius: 1px;

    background: rgba(255, 255, 255, 1);

    transform: translate(-50%, -50%) rotate(45deg);
  }
}
