@import "../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 0;

  padding: 4px 16px;

  border-radius: 8px;
  border: 1px solid $bw-300;

  & .select {
    cursor: pointer;

    & [class="dropdown-container"] {
      border: none;

      &:focus-within {
        border-color: unset;
        box-shadow: none;
      }
    }

    & [class="dropdown-heading"] {
      padding: 0;
    }

    & [class="dropdown-content"] {
      left: -16px;
      right: -16px;

      width: fit-content;

      border-radius: 8px;
    }

    & [class="options"] {
      display: flex;
      flex-direction: column;
      gap: 4px;

      padding: 8px 4px;
    }

    & [class="select-item "] {
      padding: 0;
    }

    & [class="select-item selected"] {
      padding: 0;

      background: none;
    }

    & [class="item-renderer "] {
      & input {
        width: 18px;
        height: 18px;

        margin: 0;

        border-radius: 4px;

        &:checked::before {
          background: $orange-600;
        }
      }
    }
  }
}
