.root {
  display: flex;
  flex-direction: column;
  gap: 56px;

  padding: 32px 24px 63px 24px;

  & .userData {
    gap: 18px;

    padding: 0;

    border: none;

    font-size: 20px;
    line-height: 26px;
  }

  & .avatar {
    width: 80px;
    height: 80px;
  }
}
