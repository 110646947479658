.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;

  padding: 64px 105px;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  width: 100%;
}

.download {
  display: flex;
  align-items: center;
  gap: 8px;
}

.btn {
  height: unset;

  padding: 0;
}

.upload {
  width: 540px;
}

.wrapperLoad {
  margin-top: unset;
}

.loadTemp {
  max-width: 480px;
  width: 100%;
}

.nextBtn {
  width: 152px;
  justify-content: center;
}
