@import "../../assets/styles/services";

.root {
  display: flex;

  border-bottom: 1px solid $bw-500;

  font-size: 14px;
  font-weight: 500;
  line-height: 19px;

  color: $bw-500;
}

.wrapper {
  width: 100%;
}

.inputTab {
  display: none;
}

.inputTab:checked + label {
  display: flex;

  margin-bottom: -1px;
  padding-bottom: 7px;

  font-weight: 600;

  color: $black;

  border-bottom: solid 4px $orange-600;
}

.labelTab {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 0;

  cursor: pointer;

  font-size: 16px;
  line-height: 22px;

  &:hover {
    color: $black;
  }

  &:focus {
    color: $black;
  }

  &:active {
    color: $black;
  }
}
