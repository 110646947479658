@import "../../../../assets/styles/services.scss";

.root {
  max-width: 371px;
  width: 100%;
  height: 288px;

  border-radius: 16px;
  border: 1px solid rgba(226, 226, 226, 1);

  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08);

  .title {
    color: $black;
    text-decoration: none;

    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    &:hover {
      color: $orange-500;
    }

    &:active {
      color: $orange-700;
    }
  }
}

.imgCard {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 198px;
  width: 100%;

  border-radius: 16px 16px 0 0;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.disabledImage {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  border-radius: 16px 16px 0 0;

  background: rgba(255, 255, 255, 0.75);
}

.wrapper {
  position: relative;

  display: flex;
  justify-content: space-between;

  padding: 32px 16px;

  box-shadow: 0px -5px 20px 0px rgba(99, 99, 99, 0.19);
}

.dotsBtn {
  & svg {
    & path {
      stroke: $bw-500;
    }

    &:hover {
      & path {
        stroke: $bw-700;
      }
    }

    &:active {
      & path {
        stroke: $bw-700;
      }
    }
  }
}

.disabled {
  & svg {
    & path {
      stroke: $bw-300;
    }
    &:hover {
      & path {
        stroke: $bw-300;
      }
    }
  }
}

.portalWrapper {
  align-items: center;
  justify-content: center;
}
