.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  padding-top: 8px;
}

.dataList {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
