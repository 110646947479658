@import "../../assets/styles/services.scss";

.root {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 488px;
  width: 100%;
  height: 100%;

  padding: 88px 64px 88px 64px;

  border-radius: 16px;

  background: $white;
}

.closeWrapper {
  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  justify-content: flex-end;

  width: 100%;

  padding: 24px;
}

.closeIcon {
  width: 32px;
  height: 32px;

  & path {
    stroke: $bw-500;
  }
}
