@import "../../../../../../assets/styles/services.scss";

.root {
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 8px 16px;

  &:hover {
    border-radius: 4px;

    background: $bw-200;

    color: $orange-600;

    & .checkbox::before {
      border-color: $orange-600;
    }
  }

  &.selected {
    padding: 8px 16px;

    border-radius: 4px;

    background: $bw-200;

    color: $orange-600;
  }
}
