@import "../../../../assets/styles/services";

.root {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  width: 188px;

  padding: 34px 25.5px;

  border: 1px solid #f1f1f1;
  border-radius: 16px;

  box-shadow: 0 8px 24px 0 #00000014;
}

.title {
  font-weight: 500;
  line-height: 22px;
}

.mark {
  position: absolute;
  top: -8px;
  right: -8px;

  padding: 4px 12px 4px 12px;

  border-radius: 30px;

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;

  color: $white;

  background: $orange-600;
}
