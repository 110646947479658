.root {
  padding: 10px 0 18px 24px;

  border-bottom: 1px solid rgba(241, 241, 241, 1);

  box-shadow: 0 4px 10px 0 rgba(49, 49, 49, 0.04);

  background: rgba(255, 255, 255, 1);
}

.wrapperSelect {
  width: 225px;
}
