@import "../../../../assets/styles/services";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  margin-top: 100px;
}

.btn {
  justify-content: center;

  width: 152px;
}

.text {
  color: $bw-500;
}

.customUpload {
  width: 100%;
}

.loadTemp {
  margin-top: 100px;
}

.portalWrapper {
  justify-content: flex-end;

  padding: 16px;
}
