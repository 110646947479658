@import "../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 70px;

  padding: 4px 16px;

  border-radius: 8px;
  border: 1px solid $bw-300;

  & .input {
    height: 38px;

    border: none;

    outline: none;

    &::placeholder {
      font-size: 16px;

      color: $bw-400;
    }
  }
}
