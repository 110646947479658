.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  gap: 6px;

  width: 74px;
  height: 23px;
}

.logoIcon {
  width: 23px;
  height: 23px;
}

.logoTextIcon {
  width: 45px;
  height: 20px;
}
