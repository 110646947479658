@import "../../../../assets/styles/services";

.root {
  max-width: 868px;
  width: 100%;

  margin: 40px 0 0 24px;

  border-radius: 16px;

  border: 1px solid $bw-300;

  box-shadow: 0 8px 24px 0 #00000014;
  border-spacing: 0;
  border-collapse: separate;

  tr td {
    border-bottom: 1px solid rgba(226, 226, 226, 1);
  }

  tr:last-child td {
    border-bottom: unset;
  }

  .labelStyles {
    grid-column-gap: unset;
  }

  .titleBtn {
    padding: unset;

    font-size: 14px;
    line-height: 19px;

    color: $bw-900;
  }

  .checkboxContainer {
    width: fit-content;

    padding: 25px 36px;
  }
}

.tableRow {
  background: $bw-200;

  th:last-child {
    border-radius: 0 16px 0 0;
  }

  th:first-child {
    border-radius: 16px 0 0 0;
  }
}

.headerCurrent {
  display: flex;
  justify-content: center;

  width: 92px;
}

.tableRow th {
  padding: 14px 24px;

  background: $bw-200;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;

  color: $bw-700;
}

.row td {
  padding: 16px 24px;
}

.dateColum {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.timeColum {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;

  color: $bw-600;
}

.titleColum {
  font-size: 14px;
  line-height: 19px;
}

.buttonDown {
  height: unset;

  padding: unset;
}

.btnDelete {
  & svg {
    & path {
      stroke: $bw-500;
    }

    &:hover {
      & path {
        stroke: $bw-700;
      }
    }

    &:active {
      & path {
        stroke: $bw-700;
      }
    }
  }
}

.disabled {
  svg {
    & path {
      stroke: $bw-300;
    }

    &:hover {
      & path {
        stroke: $bw-300;
      }
    }
  }
  cursor: default;
}

.portalWrapper {
  justify-content: center;
  align-items: center;
}
