@import "../../../../assets/styles/services";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.text {
  text-align: center;

  max-width: 500px;

  color: $bw-600;
}
