@import "../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;
}

.label {
  font-size: 14px;
  line-height: 19px;
}

.inputWrapper {
  position: relative;

  width: 100%;

  & .eyeBtn {
    position: absolute;
    top: 8px;
    right: 12px;
  }
}

.input {
  height: 40px;
  width: 100%;

  padding: 10px 12px;

  border-radius: 8px;
  border: 1px solid $bw-500;

  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  outline: none;

  &::placeholder {
    color: $bw-500;
  }

  &:focus {
    border-color: $bw-700;
  }

  &.error {
    border-color: $error;
  }
}

.errorWrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 14px;
  line-height: 19px;

  color: $error;
}

.errorIconWrapper {
  width: 20px;
  height: 20px;
}

.errorIcon {
  width: 20px;
  height: 20px;

  & path {
    stroke: $error;
  }
}

.eyeIcon {
  width: 24px;
  height: 24px;

  & path {
    stroke: $bw-500;
  }
}
