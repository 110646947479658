@import "../../../assets/styles/services";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
}

.barWrapper {
  display: flex;
  gap: 20px;
}

.wrapper {
  height: 540px;
}

.loader {
  margin-top: 50px;
  background:
    radial-gradient(farthest-side, $orange-600 94%, #0000) top/9px 9px no-repeat,
    conic-gradient(#0000 30%, $orange-600);
}
