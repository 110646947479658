.root {
  display: flex;
  flex-direction: column;
  gap: 37px;

  .link {
    width: fit-content;

    text-decoration: none;

    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
}

.wrapperText {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  text-align: center;
}

.btn {
  justify-content: center;
}
