@import "../../../../assets/styles/services.scss";

.root {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;

  padding: 4px;

  border-radius: 8px;
  border-bottom: 1px solid #dedede;

  background: $white;

  box-shadow: 0 6px 16px 0 #00000014;

  & .logoutBtn {
    gap: 8px;

    width: 100%;

    padding: 8px 16px;

    border-radius: 4px;

    font-size: 14px;
    line-height: 19px;

    color: $bw-800;

    &:hover {
      background-color: $bw-200;
    }
  }

  .wrapperNavigation {
    padding: 12px 0 0 0;
  }

  .stylesLink {
    padding: 8px 16px;

    font-size: 14px;
    line-height: 19px;

    &:hover {
      border-radius: 8px;
    }
  }
}

.actionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
