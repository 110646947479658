@import "../../assets/styles/services";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 32px;
  height: 32px;

  border-radius: 50%;
  background:
    radial-gradient(farthest-side, $orange-600 94%, #0000) top/9px 9px no-repeat,
    conic-gradient(#0000 30%, $orange-600);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 2.4px),
    #000 0
  );
  animation: spin 1s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
}
