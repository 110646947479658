@import "../../../../assets/styles/services";

.root {
  display: flex;
  flex-direction: column;
  gap: 34px;

  max-width: 541px;
  width: 100%;
}

.description {
  color: $bw-600;
}

.afterLogin {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
