.root {
  margin: 0;
}

.t1 {
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}

.t2 {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.t3 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.t4 {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.t5 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.t6 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.t7 {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
