@import "../../../../assets/styles/services";

.root {
  position: sticky;
  top: 0;

  max-width: 240px;
  width: 100%;
  height: 100vh;

  background: $bw-100;

  overflow: hidden;

  transition: 0.4s;
}

.rootCloseMenu {
  max-width: 100px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 21.5px 8px 21.5px 24px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  width: 120px;
  height: 37px;
}

.logoWrapperClose {
  gap: 7px;

  width: unset;
}

.logoIcon {
  width: 37px;
  height: 37px;
}

.logoTextIcon {
  width: 72px;
  height: 31px;
}

.logoTextIconClose {
  display: none;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.arrow {
  cursor: pointer;

  transition: transform 0.5s ease-in-out;
  & path {
    stroke: $bw-500;
  }
}

.arrowClose {
  animation: rotateAnimation 0.5s forwards;
}

.copyright {
  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 0 12px 20px 24px;

  color: $bw-600;
}
