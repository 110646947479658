.root {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  padding: 32px 0 0 24px;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}
