@import "../../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;

  //   max-width: 273px;
  width: 100%;

  border-radius: 8px;
  border: 2px solid $success;

  background: $white;

  box-shadow: 0px 8px 24px 0px #00000014;

  &.disabled {
    border-color: $bw-400;

    & .header {
      background: $bw-400;
    }
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 28px;

  background: $success;

  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  color: $white;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex-grow: 1;

  padding: 24px 16px 16px 16px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 64px;
}

.title {
  text-align: center;
}

.list {
  flex-grow: 1;
}

.listItem {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 8px;

  font-size: 14px;
  line-height: 19px;

  color: $bw-800;

  &:not(:first-child) {
    margin-top: 16px;
  }

  &:before {
    content: "";

    width: 20px;
    height: 20px;

    background-image: url("../../../../assets/icons/checkmark-icon.svg");
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.period {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
