@import "../../../assets/styles/services";

.root {
  position: relative;

  width: fit-content;
}

.input {
  display: none;

  &:checked + .label::before {
    background: $orange-600;
    background-image: url("../../../assets/icons/checkboxChecked-icon.svg");

    background-repeat: no-repeat;
    background-position: center;
  }
}

.label {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 10px;
  align-items: center;

  cursor: pointer;

  &:hover {
    &::before {
      border-color: $orange-600;
    }
  }

  &:active {
    &::before {
      border-color: $orange-400;
    }
  }

  &::before {
    content: "";

    width: 18px;
    height: 18px;

    border: 1.5px solid $bw-400;
    border-radius: 4px;
  }
}

.disabled {
  color: $bw-400;

  cursor: default;

  &:hover,
  &:active {
    color: $bw-400;

    &::before {
      border-color: $bw-400;
    }
  }

  &::before {
    //background-image: url("/icons/Disabled.svg");
    border-color: $bw-400;
  }
}
