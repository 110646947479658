@import "../../../assets/styles/services.scss";

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  height: 40px;

  padding: 9px 20px;

  border: none;
  border-radius: 8px;

  background: $orange-600;

  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: $white;

  cursor: pointer;

  outline: none;

  &:hover {
    background: $orange-500;
  }

  &:active {
    background: $orange-700;
  }

  &:disabled {
    background: $bw-500;

    cursor: default;
  }

  &.secondary {
    border: 1.5px solid $orange-600;

    background: $white;

    color: $orange-600;

    &:hover {
      border-color: $orange-500;

      color: $orange-500;
    }

    &:active {
      border-color: $orange-700;

      color: $orange-700;
    }

    &:disabled {
      border-color: $bw-500;

      color: $bw-500;
    }
  }

  &.text {
    background: $white;

    color: $orange-600;

    &:hover {
      color: $orange-500;
    }

    &:active {
      color: $orange-700;
    }

    &:disabled {
      color: $bw-500;
    }
  }

  &.unstyled {
    justify-content: flex-start;
    align-items: center;
    gap: 0;

    height: unset;
    width: fit-content;

    padding: 0;

    border: none;
    border-radius: 0;

    background: none;

    font-weight: unset;
    color: unset;

    &:disabled {
      background: unset;

      cursor: default;

      pointer-events: none;
    }
  }

  &.center {
    justify-content: center;
  }

  &.end {
    justify-content: flex-end;
  }
}
