.root {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 100px;

  .demoTemplate {
    max-width: 417px;
  }
}
