.root {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;

  width: 100%;
  height: 100%;

  background: #000000b2;
  z-index: 999;
}
