@import "../../../../../../assets/styles/services";

.root {
  padding: 32px 8px 0 0;

  .activeLink {
    border-radius: 0 8px 8px 0;

    color: $orange-600;

    background: $bw-200;

    & svg path {
      stroke: $orange-600;
    }
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-weight: 500;

  list-style-type: none;
}

.inputTab {
  display: none;
}

.labelTab {
  display: flex;

  padding: 8px 24px;

  cursor: pointer;

  text-decoration: none;
  color: unset;

  white-space: nowrap;

  &:hover {
    border-radius: 0 8px 8px 0;

    background: $bw-200;
  }

  &:active {
    color: $orange-600;

    & svg path {
      stroke: $orange-600;
    }
  }
}

.labelTabClose {
  justify-content: center;

  padding: 8px 34px 8px 38px;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
