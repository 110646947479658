.root {
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 18px 16px 22px;

  border-bottom: 1px solid #dedede;

  font-size: 14px;
  line-height: 19px;

  & .avatarIcon {
    width: 24px;
    height: 24px;
  }
}
