@import "../../../../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 2px;

  padding: 8px 16px;

  border: 1px solid rgba(226, 226, 226, 1);
  border-radius: 8px;
}

.value {
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;

  color: $orange-600;
}

.description {
  color: $bw-700;
}
