@import "../../assets/styles/services.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  margin: 12px 45px 0;
}

.title {
  color: $orange-600;
}

.wrapperSteps {
  display: flex;
  gap: 16px;
}

.step {
  flex-grow: 1;
  height: 5px;
  width: 60px;

  border-radius: 5px;
  background-color: $bw-400;
}

.active {
  background-color: $orange-600;
}

.footerLogin {
  position: sticky;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 86px;

  background: #000000A6;
}

.loginBtn {
  justify-content: center;

  width: 173px;
}