@import "../../../../assets/styles/services";

.checkboxContent {
  font-size: 14px;
  line-height: 22px;

  & .link {
    font-size: 14px;
    line-height: 22px;

    text-decoration: none;
  }
}
