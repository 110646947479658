@import "../../../../assets/styles/services";

.root {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  max-width: 542px;
  width: 100%;

  padding: 64px;

  border-radius: 16px;

  background: $white;

  .btn {
    justify-content: center;
    width: 152px;
  }
}

.wrapperTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.wrapper {
}

.wrapperBtn {
  display: flex;
  gap: 16px;

  margin-top: 16px;
}
