@import "../../assets/styles/services.scss";

.root {
  font-size: 12px;
  line-height: 16px;

  color: $orange-600;

  &:hover {
    color: $orange-500;
  }

  &:active {
    color: $orange-700;
  }

  &.disabled {
    color: $bw-500;

    cursor: not-allowed;

    pointer-events: none;
  }
}
