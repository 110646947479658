@import "../../../../assets/styles/services.scss";

.root {
  position: absolute;
  top: 100%;
  right: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  max-width: 320px;
  width: 320px;

  padding: 12px;

  border-radius: 8px;

  background: $white;

  box-shadow: 0 12px 16px -4px #10182814;

  font-size: 12px;
  line-height: 18px;

  & .liink {
    width: fit-content;
  }
}

.text {
  line-height: 18px;

  color: $bw-600;
}
